<mat-dialog-content>
    <h3>Are you Sure?</h3>
    <div>
        Do you want to send build back to <b>Staged-Builds</b>?
    </div>
    <div>
        This will remove the build from it's current job.
    </div>
    
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    <button mat-button [mat-dialog-close]="true">Yes</button>
</mat-dialog-actions>