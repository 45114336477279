import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClipboardModule } from '@angular/cdk/clipboard';

import { MaterialModule } from './material.module';

import { DocumentTypeAutocompleteComponent } from './auto-complete/document-type-autocomplete/document-type-autocomplete.component';
import { SerialNumberChipsComponent } from './auto-complete/serial-number-chips/serial-number-chips.component';
import { SerialNumberInputComponent } from './auto-complete/serial-number-input/serial-number-input.component';
import { PartNumberInputComponent } from './auto-complete/part-number-input/part-number-input.component';
import { PartNumberDescriptionComponent } from './auto-complete/part-number-description/part-number-description.component';
import { TrimPipe } from './pipes/trim-string.pipe';
import { FileThumbnailsComponent } from './file-thumbnails/file-thumbnails.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectNumbersInputComponent } from './auto-complete/project-numbers-input/project-numbers-input.component';
import { TextDialogComponent } from './dialogs/text-dialog/text-dialog.component';
import { FormDialogComponent } from './dialogs/form-dialog/form-dialog.component';
import { CanSaveDialogComponent } from './dialogs/can-save-dialog/can-save-dialog.component';
import { ColumnEntityFolderTemplate } from './tables/column-templates/entity-folder.component';
import { ButtonContainerComponent } from './button-container/button-container.component';
import { PrintLabelComponent } from './print-label/print-label.component';
import { NgxPrintModule } from 'ngx-print';
import { SummaryLogCreationConfirmationComponent } from './dialogs/summary-log-creation-confirmation/summary-log-creation-confirmation.component';
import { JobInfoComponent } from './print-label/job-info/job-info.component';
import { BuildInfoComponent } from './print-label/build-info/build-info.component';
import { CreateBuildPaperworkDialogComponent } from './dialogs/create-build-paperwork/create-build-paperwork.component';
import { SendToStageBuildsConfirmationComponent } from './dialogs/send-to-stage-builds-confirmation/send-to-stage-builds-confirmation.component';

const COMPONENTS = [
  DocumentTypeAutocompleteComponent,
  SerialNumberChipsComponent,
  SerialNumberInputComponent,
  PartNumberInputComponent,
  PartNumberDescriptionComponent,
  FileThumbnailsComponent,
  ProjectNumbersInputComponent,
  TextDialogComponent,
  FormDialogComponent,
  CanSaveDialogComponent,
  ColumnEntityFolderTemplate,
  ButtonContainerComponent,
  PrintLabelComponent,
  SummaryLogCreationConfirmationComponent,
  CreateBuildPaperworkDialogComponent,
  SendToStageBuildsConfirmationComponent
];

const MODULES = [
  ClipboardModule,
  MaterialModule,
  NgxPrintModule,
];

const PIPES = [
  TrimPipe,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...MODULES,
  ],
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    JobInfoComponent,
    BuildInfoComponent,
  ],
  exports: [
    ...COMPONENTS,
    FormsModule,
    ReactiveFormsModule,
    ...MODULES,
    ...PIPES,
  ]
})
export class SharedModule { }
