<h2 mat-dialog-title>Create Build Paperwork</h2>
<mat-dialog-content>
    <p>Are you sure you want to create new build paperwork?</p>
    <p>Select what you want to generate/update for the build?</p>
    <div class="app-column">
        <mat-slide-toggle [(ngModel)]="createPaperwork">Build Paperwork</mat-slide-toggle>
        <mat-slide-toggle [(ngModel)]="createComponentRecord">Component Record Folder</mat-slide-toggle>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="primary" [mat-dialog-close]="{createPaperwork,createComponentRecord}">Create Build Files</button>
</mat-dialog-actions>