import { Component } from '@angular/core';

export interface BuildFiles {
  createPaperwork: boolean;
  createComponentRecord: boolean
}

@Component({
  selector: 'dialog-create-build-paperwork',
  templateUrl: './create-build-paperwork.component.html',
  styleUrls: ['./create-build-paperwork.component.css']
})
export class CreateBuildPaperworkDialogComponent {
  createPaperwork: boolean = true;
  createComponentRecord: boolean = false;
}
