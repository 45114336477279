import { Component } from '@angular/core';

@Component({
  selector: 'dialog-send-to-stage-builds-confirmation',
  templateUrl: './send-to-stage-builds-confirmation.component.html',
  styleUrls: ['./send-to-stage-builds-confirmation.component.css']
})
export class SendToStageBuildsConfirmationComponent {

}
